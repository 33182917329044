import React from 'react';

import PageWrap from 'components/PageWrap';
import { Section } from 'components/Section';
import { PagePicTitle } from 'components/PagePicTitle';

import { FeaturePoint } from 'components/FeaturePoint';
import CallsToAction from 'components/CallsToAction';
import { Separator } from 'components/Separator';
import IndyLink from 'components/IndyLink';
import { SEO } from 'components/Helmets';

import LibraryIcon from 'media/shared/modIcons/Library.inline.svg';

import itemDetailsImg from 'media/Screenshots/Library/ItemDetails.png';
import itemListImg from 'media/Screenshots/Library/ItemList.png';
import permissionsImg from 'media/Screenshots/Library/Permissions.png';
import preferencesImg from 'media/Screenshots/Library/Preferences.png';
import fileUploadImg from 'media/Screenshots/Library/FileUpload.png';

export default function ModLibrary({data}){
    const CTATypes = ['SignUp', 'Demo'];
    const pagePath = "/modules/library";

    return (
        <PageWrap noSEO={true} path={pagePath}>
            <SEO
                title="The Library Module - Mashoom"
                description="Store all your files in the cloud, as you would a file server. A powerful permissions system and simple tools allows you to use this is your sole file storage, either for you, or your corporation."
                previewImgKey="library"
                path={pagePath}
            />
            <PagePicTitle
                imageCmp={LibraryIcon}
                imageAlt="Library module icon">
                <h1>The Library Module</h1>
                <p>Store all your files in the cloud, as you would a file server. A powerful permissions system and simple tools allows you to use this is your sole file storage, either for you, or your corporation.</p>
            </PagePicTitle>
            <FeaturePoint
                title="Shouldn't this be simple?"
                image={itemListImg}
                pageInd="1"
            >
                <p>Storing files so they are both highly secure but available when you need them can be made very complicated. At the end of the day, you want to be able to get to your files as quickly as possible, with as least fuss as possible.</p>
                <p className="paraSpaceTop">The Library module provides a powerful, scalable and simple solution. A simple folder structure allows you to store files like you are used to. There is no limits to how many folder levels you can have and you can download a whole folder in one go as a .zip.</p>
                <p className="paraSpaceTop">Projects allow clutter management and simple filters and search functions allow you to find what you are after as quickly as possible.</p>
            </FeaturePoint>
            <Separator />
            <FeaturePoint
                title="A very simple versioning technique"
                image={fileUploadImg}
                pageInd="2"
            >
                <p>No two files in a folder can be named the same, everyone is used to this. We simply say that if you are uploading a file of the same name as one that already exists, the existing file is versioned to the new one.</p>
                <p className="paraSpaceTop">This allows you to upload all a folder on your computer to Mashoom, we will version those that need versioning, and add those that don't exist yet.</p>
            </FeaturePoint>
            <Separator />
            <FeaturePoint
                title="Migrate all your files instantly"
                image={preferencesImg}
                pageInd="3"
            >
                <p>Along with being able to download a whole folder and sub folder as a .zip file, we allow you to upload a .zip file (of any size) and unpack it into it's files / folder. Simply upload the .zip, then select the "unpack" option.</p>
                <p className="paraSpaceTop">If you upload and unpack a zip into a folder that already has files / sub folders, we will add files / folders that don't exist and version files that do. This allows you to take advantage of this feature even after you initially migrated your data.</p>
            </FeaturePoint>
            <Separator />
            <FeaturePoint
                title="Outrageously powerful permissions"
                image={permissionsImg}
                pageInd="4"
            >
                <p>This module's party piece; an incredibly powerful permissions system. Simply, you can allow different levels of access on a folder by folder basis, to individual users or groups of users. Permission propagate to sub folder and we provide a clear list of access rights to cross check the permissions you have set.</p>
                <p className="paraSpaceTop">You can give full access to a top level folder, then restrict access to a sub folder, then remove permissions to another folder. You can also grant admin privileges to a folder to delegate access controls of a Project or Folder, vital for large organizations.</p>
                <p>Permission changes can also be scheduled, ideal for setting deadlines for access or uploads.</p>
            </FeaturePoint>
            <Separator />
            <FeaturePoint
                title="Complete traceability"
                image={itemDetailsImg}
                pageInd="5"
            >
                <p>A complete viewing, download and editing history is stored for every folder, allowing a full audit trail if you require one. This can also be filtered by action type, person and across all sub folders, allowing you to really drill down into your data.</p>
                <p className="paraSpaceTop">Our free <IndyLink adr="addons">reporting add-on</IndyLink> also allows a top-down overview of this modules usage, displaying activity of projects and how many files, what size and what types of files are being uploaded.</p>
            </FeaturePoint>
            <Section id="FeatureCTA">
                <div id="FeatureCTACont">
                    <h2>Like what your hear?</h2>
                    <CallsToAction types={CTATypes}></CallsToAction>
                </div>
            </Section>
        </PageWrap>
    );
}